import { ContentType } from 'constants/resources'

import { Session as ApiSession } from 'types/api'

type ReviewValues = {
  isLegacyReviewer: boolean
  isAutoAssignReviewer: boolean
}

export class Session {
  id: ApiSession['id']
  user_id: ApiSession['user_id']
  email: ApiSession['email']
  firstName: ApiSession['first_name']
  lastName: ApiSession['last_name']
  avatarUrl: ApiSession['avatar_url']
  enabledFeatures: ApiSession['enabled_features']
  roles: ApiSession['roles']
  reviewerPermissions: ApiSession['reviewer_permissions']
  apiToken: ApiSession['api_token']
  apiEndpoint: ApiSession['api_endpoint']
  contentTypes: ContentType[]
  ftp: ApiSession['ftp']
  wo: ApiSession['wo']
  reviewContentTypes: ApiSession['review_content_types']
  entityVerificationUrl: ApiSession['entity_verification_url']
  taxQuestionnaireState: ApiSession['tax_questionnaire_state']
  taxRates: ApiSession['tax_rates']
  referralsToken: ApiSession['referrals_token']
  referralsUrl: ApiSession['referrals_url']
  referralsGoalID: ApiSession['referrals_goal_id']
  unpaidAccountBalance: ApiSession['unpaid_account_balance']
  unpaidFlaggedAccountBalance: ApiSession['unpaid_flagged_balance']
  unpaidUSAccountBalance: ApiSession['unpaid_us_balance']
  unpaidUSVideoAccountBalance: ApiSession['unpaid_us_video_balance']
  current_on_terms: ApiSession['current_on_terms']
  uploadConfig: ApiSession['upload_config']
  directory_confirmation: ApiSession['directory_confirmation']
  needs_verification: ApiSession['needs_verification']
  disabled_categories: ApiSession['disabled_categories']

  constructor(session: ApiSession) {
    this.id = session.id
    this.user_id = session.user_id
    this.email = session.email
    this.firstName = session.first_name
    this.lastName = session.last_name
    this.avatarUrl = session.avatar_url
    this.enabledFeatures = session.enabled_features
    this.roles = session.roles
    this.reviewerPermissions = session.reviewer_permissions
    this.apiToken = session.api_token
    this.apiEndpoint = session.api_endpoint
    this.ftp = session.ftp
    this.wo = session.wo
    this.reviewContentTypes = session.review_content_types
    this.entityVerificationUrl = session.entity_verification_url
    this.taxQuestionnaireState = session.tax_questionnaire_state
    this.taxRates = session.tax_rates
    this.referralsToken = session.referrals_token
    this.referralsUrl = session.referrals_url
    this.referralsGoalID = session.referrals_goal_id
    this.unpaidAccountBalance = session.unpaid_account_balance
    this.unpaidFlaggedAccountBalance = session.unpaid_flagged_balance
    this.unpaidUSAccountBalance = session.unpaid_us_balance
    this.unpaidUSVideoAccountBalance = session.unpaid_us_video_balance
    this.current_on_terms = session.current_on_terms
    this.directory_confirmation = session.directory_confirmation
    this.needs_verification = session.needs_verification
    this.disabled_categories = session.disabled_categories

    this.uploadConfig = session.upload_config
    this.contentTypes = []
    if (session.content_types) {
      session.content_types.forEach(ct => {
        if (Object.values(ContentType)?.includes(ct as ContentType)) {
          this.contentTypes.push(ct as ContentType)
        }
      })
    }
  }

  get isAdmin(): boolean {
    if (!this.roles) {
      return false
    }

    return this.isSuperAdmin || this.isRegularAdmin || this.isLimitedAdmin
  }

  get isSuperAdmin(): boolean {
    return Boolean(this.roles?.includes('super_admin'))
  }

  get isFinanceAdmin(): boolean {
    return Boolean(this.roles?.includes('finance_admin'))
  }

  get isDirector(): boolean {
    return (
      this.roles.includes('art_director') || this.roles.includes('super_admin')
    )
  }

  get isRegularAdmin(): boolean {
    return Boolean(this.roles?.includes('admin'))
  }

  get isLimitedAdmin(): boolean {
    return Boolean(this.roles?.includes('limited_admin'))
  }

  get isBundlesContributor(): boolean {
    return Boolean(this.roles?.includes('bundles_contributor'))
  }

  get isMotionGraphicsContributor(): boolean {
    return Boolean(
      this.roles?.includes('motion_graphics_contributor') &&
        this.enabledFeatures?.includes('motion_graphics_upload')
    )
  }

  hasFeature(feature: string): boolean {
    return this.enabledFeatures?.includes(feature)
  }

  get defaultTaxRate(): number {
    return this.taxRates.default || 0
  }

  get canReviewContributors(): ReviewValues {
    return {
      isLegacyReviewer:
        !this.enabledFeatures?.includes('auto_assign_review') ||
        Boolean(
          this.reviewerPermissions &&
            Boolean(!this.reviewerPermissions.auto_assign_reviews)
        ),
      isAutoAssignReviewer:
        this.enabledFeatures?.includes('auto_assign_review') &&
        Boolean(
          this.reviewerPermissions &&
            Boolean(this.reviewerPermissions.auto_assign_reviews)
        ),
    }
  }
}
