import { Routes } from 'constants/index'
import { initFilters } from 'helpers/filters'
import { removeEmpty } from 'helpers/objects'
import { action, makeObservable, observable } from 'mobx'
import { AccountStatus } from 'pages/earnings/accounts/constants'
import qs from 'qs'

import { GetApiV1FinanceAccountSummariesQueryParams } from 'types/api'

export default class EarningsAccountsStore {
  defaultFilters: GetApiV1FinanceAccountSummariesQueryParams = {
    email: [],
    program: [],
    account_status: [
      AccountStatus.Approved,
      AccountStatus.Rejected,
      AccountStatus.Unapproved,
      AccountStatus.Closed,
    ],
    locked_reason: [],
    image_tax_thousands: [],
    video_tax_thousands: [],
    generated_at: [],
    payment_account: [],
    payment_account_state: [],
    // payment_method: [],
    unpaid_cents: [],
    pending_cents: [],
    paid_cents: [],
    total_cents: [],
    last_payment_at: [],
    sort_direction: 'asc',
    payment_account_or_email: undefined,
  }

  @observable filters: GetApiV1FinanceAccountSummariesQueryParams =
    initFilters<GetApiV1FinanceAccountSummariesQueryParams>(this.defaultFilters)

  constructor() {
    makeObservable(this)
  }

  @action setPaymentAccountOrEmailFilter = (emails: string[]): void => {
    this.setFilters(
      { ...this.defaultFilters, payment_account_or_email: emails },
      true
    )
  }

  @action setFilters = (
    filters: GetApiV1FinanceAccountSummariesQueryParams,
    syncUrl = false
  ): void => {
    this.filters = filters

    if (syncUrl) {
      const url =
        Routes.EarningsAccounts +
        qs.stringify(removeEmpty(this.filters), {
          encode: false,
          arrayFormat: 'comma',
          addQueryPrefix: true,
        })
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.setFilters(this.defaultFilters, true)
  }
}
