import { observer } from 'mobx-react'
import { Session } from 'types'
import Countdown from 'util/countdown'

import PopupCollection, { PopupTypes } from 'util/popups'
import ChallengeModalPopup from 'util/popups/ChallengeModal'
import ChallengeModal from './Modals/ChallengeModal'
import UserProfileModal from './Modals/UserProfileModal'
import PersonaModal from './Persona/PersonaModal'
import PersonaToast from './Persona/PersonaToast'
import ReferralsToast from './Referrals/ReferralsToast'

import { useGetApiV1UsersId } from 'api'

type Props = {
  session?: Session
  popups: PopupCollection
}

const Popups = ({ session, popups }: Props): JSX.Element | null => {
  const { data: user } = useGetApiV1UsersId({ id: session?.id || '' })

  if (!user || !session) {
    return null
  }

  popups.addContext('status', user.id_inquiry_status)
  popups.addContext('userId', user.id)

  const challengePopup = popups.getPopup<ChallengeModalPopup>(
    PopupTypes.ChallengeModal
  )

  if (popups.getPopup(PopupTypes.PersonaToast)?.canRender()) {
    return <PersonaToast user={user} session={session} />
  }

  if (popups.getPopup(PopupTypes.PersonaModal)?.canRender()) {
    return <PersonaModal user={user} session={session} />
  }

  if (popups.getPopup(PopupTypes.ReferralsToast)?.canRender()) {
    const countdown = new Countdown(new Date('2022-01-01T07:59:59Z'))
    return <ReferralsToast countdown={countdown} />
  }

  if (challengePopup?.canRender()) {
    const challenge = challengePopup.getNextAvailable()

    if (challenge) {
      return <ChallengeModal challenge={challenge} />
    }
  }

  if (
    popups.getPopup(PopupTypes.UserProfileModal)?.canRender() &&
    !session.directory_confirmation
  ) {
    return <UserProfileModal user={user} session={session} />
  }

  return null
}

export default observer(Popups)
