import React, { useMemo, useState } from 'react'
import ResourceCounter from '../../../../components/SubNav/ResourceCounter'

import DownArrow from 'ez-styles/assets/icons/arrows/arrow-big-down.svg'
import UpArrow from 'ez-styles/assets/icons/arrows/arrow-big-up.svg'

import { UsersResourceCounts } from 'types/api'

import * as S from './styled'

type Props = {
  dest?: string
  pathname?: string
  navigate?: (route: string) => void
  label: string
  icon?: JSX.Element
  children?: ChildProps | readonly ChildProps[]
  count?: Array<keyof UsersResourceCounts>
  testId?: string
  customComponent?: JSX.Element
}

type ChildProps = {
  props: Props
}

const MenuItem = ({
  dest,
  navigate,
  label,
  pathname,
  icon,
  children,
  count,
  testId,
  customComponent,
}: Props) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const isActive = useMemo(
    () => dest && pathname && dest === pathname,
    [dest, pathname]
  )

  const handleClick = () => {
    if (!children && navigate && dest) {
      navigate(dest)
    } else {
      setOpenSubMenu(!openSubMenu)
    }
  }

  const handleSubNavigate = (
    childDest: string | undefined,
    subNavigate: ((route: string) => void) | undefined
  ) => {
    if (subNavigate && childDest) {
      subNavigate(childDest)
    }
  }

  const renderArrow = () => {
    if (openSubMenu) {
      return <UpArrow className="arrow-icon" />
    } else {
      return <DownArrow className="arrow-icon" />
    }
  }

  return (
    <>
      <S.MenuItemWrapper
        onClick={handleClick}
        $isActive={isActive || false}
        data-testid={testId}
      >
        {icon ? icon : null}
        <S.StyledMenuItem variant="body1" $isActive={isActive || false}>
          {label}
        </S.StyledMenuItem>
        {customComponent ? customComponent : null}
        {children ? renderArrow() : null}
        {count ? <ResourceCounter count={count} variant="chip" /> : null}
      </S.MenuItemWrapper>
      {children && openSubMenu ? (
        <S.SubMenuWrapper>
          {React.Children.map(children, (child: ChildProps, idx: number) => {
            if (child.props.label) {
              return (
                <S.StyledMenuItem
                  key={idx}
                  variant="body1"
                  $isActive={child.props.dest === child.props.pathname}
                  onClick={() =>
                    handleSubNavigate(child.props.dest, child.props.navigate)
                  }
                  $isChild
                  data-testid={child.props.testId}
                >
                  {child.props.label}
                </S.StyledMenuItem>
              )
            }
          })}
        </S.SubMenuWrapper>
      ) : null}
    </>
  )
}

export default MenuItem
