import { Rating } from 'types'
import { RejectionReason } from 'types/api'

export type ResourceUpdate = {
  id: string
  title?: string
  description?: string
  keywords?: string[]
  rating?: number
  license?: 'free' | 'pro' | 'editorial'
  comment_text?: string
  rejection_reason_id?: number
  state?:
    | 'started'
    | 'submitting'
    | 'submitted'
    | 'needs_corrections'
    | 'flagged'
    | 'rejected'
    | 'approving'
    | 'approved'
    | 'inactive'
  rejecter_id?: number
}

export enum EditModalActionType {
  STATUS = 'status',
  TITLE = 'title',
  KEYWORDS = 'keywords',
  RATING = 'rating',
  VIEW = 'view',
}

export enum StatusType {
  APPROVING = 'approving',
  APPROVED = 'approved',
  ACTION_NEEDED = 'needs_corrections',
  REJECTED = 'rejected',
  FLAGGED = 'flagged',
  STARTED = 'started',
  SUBMITTED = 'submitted',
  SUBMITTING = 'submitting',
  INACTIVE = 'inactive',
}

export enum RejectionType {
  NEEDS_CORRECTIONS = 'needs_corrections',
  FLAGGED = 'flagged',
  ACCEPT_AS_FREE = 'accept_as_free',
  ACCEPT_AS_EDITORIAL = 'accept_as_editorial',
  PERMANENT = 'permanent',
}

type ApprovedStatusUpdate = {
  rating: Rating
}

type RejectedStatusUpdate = {
  reason_id: string
  reason_type: RejectionType
  rating?: Rating
}

export type StatusUpdateDetails = {
  approved?: ApprovedStatusUpdate
  rejected?: RejectedStatusUpdate
}

export type StatusUpdates = {
  updates?: StatusUpdateDetails
  name: string
  id: string
  selected: boolean
  disabled: boolean
}

export type RatingUpdates = {
  rating?: Rating
  name: string
  id: string
  selected: boolean
  disabled: boolean
}

export type StatusUpdatesByCategory = {
  [key: string]: StatusUpdates
}

export type RejectionReasonsByCategory = {
  [key: string]: RejectionReason[]
}

export type RatingUpdatesByCategory = {
  [key: string]: RatingUpdates
}

export enum BulkDownloadState {
  IN_PROGRESS = 'in-progress',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export type AllFilteredUpdates = {
  title?: string
  keywords?: string[]
  rating?: number
  rejection_reason_id?: number
  state?: StatusType
  rejecter_id?: number
}
