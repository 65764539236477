import { TaskState } from 'constants/resources'

import { User } from 'types/api'

export enum BulkOperationType {
  ApproveAll = 'approve-all',
  RejectAll = 'reject-all',
  SingleUpdate = 'single-update',
}

export type BulkOperation = {
  id: string
  type?: BulkOperationType
  state?: TaskState
  user?: User
  resourceId?: string
  hasFailures?: boolean
}
