import { action, makeObservable, observable } from 'mobx'
import { Session } from 'types'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'

import { Session as ApiSession } from 'types/api'

export default class AuthStore {
  api: Api
  apiSession?: ApiSession

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
  }

  @observable session: Session | undefined = undefined
  @observable tempSession: Session | undefined = undefined

  @action setSession = (session: ApiSession | Session): void => {
    if (session instanceof Session) {
      this.session = session
    } else {
      this.apiSession = session
      this.session = new Session(session)
    }
  }

  @action clearSession = (): void => {
    this.apiSession = undefined
    this.session = undefined
  }

  @action setTempSession = (session: ApiSession | Session): void => {
    if (session instanceof Session) {
      this.tempSession = session
    } else {
      this.tempSession = new Session(session)
    }
  }

  @action clearTempSession = (): void => {
    this.tempSession = undefined
  }
}
