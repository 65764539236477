import { TaskState } from 'constants/resources'
import { captureError } from 'helpers/error'
import { action, makeObservable, observable, when } from 'mobx'
import { AllAssetsStore, AuthStore, ResourceStore } from 'stores'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'

import { Resource } from 'types/api'
import {
  BulkOperation as BulkOperationResult,
  BulkOperationType,
} from 'types/operations'

export default class VideoStore {
  api: Api
  AuthStore: AuthStore
  ResourceStore: ResourceStore
  AllAssetsStore: AllAssetsStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    this.ResourceStore = container.find('ResourceStore')
    this.AllAssetsStore = container.find('AllAssetsStore')
    when(() => Boolean(this.AuthStore.session))
  }

  @observable playbackRate = 1
  @observable playedSeconds = 0
  @observable duration = 0
  @observable savingPreview = false
  @observable previewSaved = false

  @action setPlaybackRate = (rate: number) => {
    this.playbackRate = rate
  }

  @action setPlayedSeconds = (seconds: number) => {
    this.playedSeconds = seconds
  }

  @action setDuration = (value: number) => {
    this.duration = value
  }

  @action setPreviewSaved = (value: boolean) => {
    this.previewSaved = value
  }

  @action resetState = (resource: Resource) => {
    this.playbackRate = 1
    this.duration = 0
    this.previewSaved = false

    if (
      resource &&
      resource.meta_data &&
      resource.meta_data.video_thumbnail_time
    ) {
      this.playedSeconds = Number(resource.meta_data.video_thumbnail_time)
    }
  }

  @action savePreview = async (
    resource: Resource,
    seconds: number,
    isAdmin?: boolean
  ): Promise<void> => {
    const session = this.AuthStore.session
    if (!session) return

    this.savingPreview = true

    try {
      const resourceId = resource.id

      let editableResource = this.ResourceStore.getEdited(resourceId)
      if (!editableResource) {
        this.ResourceStore.set([resource])
        editableResource = this.ResourceStore.getEdited(resourceId)
      }

      editableResource.meta_data = {
        ...editableResource.meta_data,
        video_thumbnail_time: String(seconds),
      }
      // @ts-ignore - bad type handling and no need to expose from BE
      editableResource.video_thumbnail_time = String(seconds)

      if (isAdmin) {
        try {
          const adminEditedResource = {
            id: editableResource.id,
            video_thumbnail_time: String(seconds),
          }
          const editedResources = {
            resources: [adminEditedResource],
          }

          const result = await this.api.request(
            'PATCH',
            '/api/v1/admin/resources/update',
            {
              body: editedResources,
            }
          )
          const op: BulkOperationResult = {
            id: result.id,
            type: BulkOperationType.SingleUpdate,
            state: TaskState.Processing,
          }
          this.AllAssetsStore.addBulkOperation(op)
          this.previewSaved = true
        } catch {
          console.error('Failed to save preview time for resource:', resourceId)
        }
      } else {
        const { succeededIds } = await this.ResourceStore.saveEditedResources([
          resourceId,
        ])

        if (succeededIds.includes(resourceId)) {
          this.previewSaved = true
        } else {
          console.error('Failed to save preview time for resource:', resourceId)
        }
      }
    } catch (error) {
      captureError(error, 'Error while saving preview - stores/VideoStore.ts')
    } finally {
      this.savingPreview = false
    }
  }
}
