import { VALID_CHALLENGES } from 'constants/challenges'
import { differenceInSeconds, parseISO } from 'date-fns'
import { captureError } from 'helpers/error'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { DIContainer, DIInstances } from '../util/di'

import {
  ApiReferralsGoal,
  BaseChallenge,
  Challenge,
  Goal,
  ReferralsGoalId,
} from 'types'
import { Challenge as ApiChallenge } from 'types/api'
import Api from '../util/api'

export default class ReferralsStore {
  api: Api

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
  }

  @observable goals: Goal[] = []
  @observable challenges: Challenge[] = []
  @observable allValidChallenges: BaseChallenge[] = []

  getChallengeState = (
    challenge?: BaseChallenge
  ): { isLocked: boolean; isExpired: boolean; isActive: boolean } => {
    const isLocked = challenge?.start_date
      ? differenceInSeconds(new Date(), parseISO(challenge.start_date)) < 0
      : false

    const isExpired: boolean = challenge?.expiry_date
      ? differenceInSeconds(parseISO(challenge.expiry_date), new Date()) < 0
      : false

    const isActive: boolean = challenge?.published_state === 'active'

    return { isLocked, isExpired, isActive }
  }

  @action fetchGoal = async (
    goal: Goal
  ): Promise<ReferralsGoalId | undefined> => {
    try {
      const res: ReferralsGoalId = await this.api
        .use('referrals')
        .request('GET', `/api/v1/referrer/goals/${goal.id}`)

      return res
    } catch (e) {
      console.error(e)
    }
  }

  @action fetchGoals = async (): Promise<Goal[]> => {
    try {
      const data: ApiReferralsGoal[] = await this.api
        .use('referrals')
        .request('GET', '/api/v1/referrer/goals')

      if (data.length) {
        const parsedGoals: Goal[] = data.map(x => ({
          id: x.id,
          name: x.name_i18n?.en,
          propertyId: x.property_id,
          rewardAmount: parseFloat(x.reward_amount) || 0,
        }))

        runInAction(() => (this.goals = parsedGoals))

        return parsedGoals
      }

      return []
    } catch {
      return []
    }
  }

  fetchApiChallenges = async (
    pageNumber = 1,
    perPage = 50,
    challenges = [] as ApiChallenge[]
  ): Promise<ApiChallenge[]> => {
    const page: ApiChallenge[] = await this.api.request(
      'GET',
      '/api/v1/challenges',
      {
        query: { page: pageNumber, per_page: perPage },
      }
    )
    const fetchedChallenges = [...challenges, ...page]

    if (page.length >= perPage) {
      return this.fetchApiChallenges(++pageNumber, perPage, fetchedChallenges)
    } else {
      return fetchedChallenges
    }
  }

  @action fetchAllValidChallenges = async (): Promise<BaseChallenge[]> => {
    try {
      const validChallenges: BaseChallenge[] = [...VALID_CHALLENGES]

      const apiChallenges = await this.fetchApiChallenges()
      if (apiChallenges.length > 0) {
        apiChallenges.forEach((challenge: ApiChallenge) => {
          const { keyword, ...rest } = challenge

          validChallenges.push({
            ...rest,
            type: keyword,
          })
        })
      }

      const uniqueChallengesMap = new Map<string, BaseChallenge>()
      validChallenges.forEach(challenge => {
        uniqueChallengesMap.set(challenge.name, challenge)
      })
      const uniqueChallenges = Array.from(uniqueChallengesMap.values())

      runInAction(() => (this.allValidChallenges = uniqueChallenges))
      return uniqueChallenges
    } catch (e) {
      console.error(e)
      return []
    }
  }

  @action fetchChallenge = async (
    goal: Goal
  ): Promise<Challenge | undefined> => {
    try {
      const res = await this.fetchGoal(goal)

      if (res?.dependencies?.length) {
        const totalDep = res.dependencies.find(
          x => x.goal_dependency_id && !x.event_id
        )
        const approvedDeps = res.dependencies.filter(
          x => !x.goal_dependency_id && x.event_id
        )

        const validChallenge = this.allValidChallenges.find(
          x => x.name === goal.name
        )

        const { isLocked, isExpired, isActive } =
          this.getChallengeState(validChallenge)

        const progress = {
          total: totalDep?.count || 0,
          approved: approvedDeps.reduce((acc, dep) => (acc += dep.count), 0),
        }

        return {
          ...goal,
          type: validChallenge?.type,
          start_date: validChallenge?.start_date,
          expiry_date: validChallenge?.expiry_date,
          details_url: validChallenge?.details_url,
          complete_badge: validChallenge?.complete_badge,
          incomplete_badge: validChallenge?.incomplete_badge,
          isLocked,
          isExpired,
          isActive,
          isCompleted: progress.approved >= progress.total,
          progress,
        } as Challenge
      }
    } catch (e) {
      captureError(
        e,
        'Error while fetching challenge - stores/ReferralsStore.ts'
      )
    }
  }

  @action fetchChallenges = async (): Promise<Challenge[] | undefined> => {
    try {
      const goals = await this.fetchGoals()
      const allValidChallenges = await this.fetchAllValidChallenges()

      if (goals.length) {
        const validNames = allValidChallenges
          .filter(c => c.published_state === 'active')
          .map(x => x.name)
        const challengeGoals = goals.filter(goal =>
          validNames.includes(goal.name)
        )

        const parsedChallenges = await Promise.all(
          challengeGoals.map(goal => this.fetchChallenge(goal))
        )

        // filter expired challenges
        const filtered: Challenge[] = parsedChallenges
          ? (parsedChallenges.filter(c => {
              if (!c) {
                return false
              }

              if (c.isExpired && !c.isCompleted) {
                return false
              }

              if (!c.isActive) {
                return false
              }

              return true
            }) as Challenge[])
          : []

        runInAction(() => (this.challenges = filtered))
        return filtered
      }

      return []
    } catch (e) {
      captureError(
        e,
        'Error while fetching challenges - stores/ReferralsStore.ts'
      )
    }
  }
}
