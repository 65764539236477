import { Routes } from 'constants/index'
import UploadIcon from 'ez-styles/assets/icons/circle/circle-plus.svg'
import MenuIcon from 'ez-styles/assets/icons/menu.svg'
import VecteezyIcon from 'ez-styles/assets/img/logos/rebrand/orange-logomark.svg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Notifications from '../Notifications'
import Menu from './menu'

import { ContributorNotification } from 'types/api'
import { Session } from '../../types'

import { gevent } from 'helpers/gtag'

import * as S from './styled'

type Props = {
  notifications: ContributorNotification[] | null
  notificationsLoading: boolean
  session?: Session
}

const MobileNavigation = ({
  notifications,
  notificationsLoading,
  session,
}: Props) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const navigate = useNavigate()

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar)
  }

  const handleUploadPress = () => {
    gevent('upload', 'Any', { source: 'Mobile Nav Button' })
    navigate(Routes.PortfolioUpload)
  }

  if (!session) {
    return null
  }

  return (
    <>
      <S.MobileMenu data-testid="cm-mobile-nav">
        <S.LogoWrapper onClick={() => navigate('/')} data-testid="nav-logo">
          <VecteezyIcon />
        </S.LogoWrapper>
        <S.ActionsWrapper>
          <>
            <S.NavIconWrapper
              onClick={handleUploadPress}
              data-testid="upload-nav-icon"
            >
              <UploadIcon />
            </S.NavIconWrapper>

            <Notifications
              notifications={notifications}
              loading={notificationsLoading}
              isMobile
              data-testid="notifications-nav-icon"
            />
          </>
          <S.NavIconWrapper onClick={toggleSidebar} data-testid="menu-nav-icon">
            <MenuIcon />
          </S.NavIconWrapper>
        </S.ActionsWrapper>
      </S.MobileMenu>
      <Menu
        isOpen={showSidebar}
        onClose={() => setShowSidebar(false)}
        session={session}
      />
    </>
  )
}

export default MobileNavigation
