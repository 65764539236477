import { action, makeObservable, observable, when } from 'mobx'
import AuthStore from 'stores/AuthStore'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'
import PopupCollection from 'util/popups'
import { Category, UsersResourceCounts } from '../api'

export default class NavStore {
  api: Api
  popups: PopupCollection
  AuthStore: AuthStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    setTimeout(this.pollResourceCounts, 60 * 1000)

    this.popups = new PopupCollection()

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        this.popups.addContext('session', this.AuthStore.session)
      }
    )
  }

  @observable contributorResourceCounts: UsersResourceCounts = {}
  @observable categories: Category[] = []
  @observable formIsDirty = false

  @action setContributorResourceCounts = (
    counts: UsersResourceCounts
  ): void => {
    this.contributorResourceCounts = counts
    this.popups.addContext('resourceCounts', counts)
  }

  @action modifyCount = (
    delta: number,
    countType: keyof UsersResourceCounts
  ): void => {
    if (this.contributorResourceCounts) {
      if (this.contributorResourceCounts[countType]) {
        this.contributorResourceCounts[countType] =
          (this.contributorResourceCounts[countType] || 0) + delta
      } else {
        this.contributorResourceCounts[countType] = delta
      }
    }
  }

  @action updateCounts = (): Promise<void> => {
    return this.pollResourceCounts()
  }

  @action setCategories = (categories: Category[]): void => {
    this.categories = categories
  }

  @action fetchCategories = async (): Promise<Category[] | undefined> => {
    try {
      const res = await this.api.request('GET', '/api/v1/categories')

      if (res) {
        this.setCategories(res as Category[])
        return res
      }
    } catch (e) {
      console.error(e)
    }
  }

  @action setFormIsDirty = (formIsDirty: boolean) => {
    this.formIsDirty = formIsDirty
  }

  pollResourceCounts = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (session) {
      try {
        const { resource_counts } = await this.api.request(
          'GET',
          `/api/v1/users/${session.id}`,
          {
            query: {
              'additional_fields[]': 'resource_counts',
            },
          }
        )
        this.setContributorResourceCounts(resource_counts)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
