import { Chip } from '@mui/material'
import { observer } from 'mobx-react'
import { NavStore } from 'stores'
import { useInstance } from 'util/di'

import { UsersResourceCounts } from 'types/api'

type Props = {
  count?: Array<keyof UsersResourceCounts>
  variant?: 'chip'
}

const PortfolioResourceCounter = ({
  variant,
  count,
}: Props): JSX.Element | null => {
  const { contributorResourceCounts } = useInstance<NavStore>('NavStore')
  let total = 0

  if (count && contributorResourceCounts) {
    count.forEach(stateCount => {
      if (contributorResourceCounts[stateCount]) {
        total += contributorResourceCounts[stateCount] || 0
      }
    })
  }

  if (!total) {
    return null
  }

  if (variant == 'chip') {
    return <Chip size="small" label={total.toLocaleString()} />
  } else {
    return <> ({total.toLocaleString()})</>
  }
}

export default observer(PortfolioResourceCounter)
