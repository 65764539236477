import { Routes, SortOrder } from 'constants/index'
import { initFilters } from 'helpers/filters'
import { removeEmpty } from 'helpers/objects'
import { action, makeObservable, observable } from 'mobx'
import { AccountStatus } from 'pages/earnings/accounts/constants'
import { SourceCategory } from 'pages/earnings/charges/constants'
import qs from 'qs'

import {
  FinanceBulkOperation,
  GetApiV1FinanceChargesQueryParams,
} from 'types/api'

export default class EarningsChargesStore {
  defaultFilters: GetApiV1FinanceChargesQueryParams = {
    code: [],
    state: [],
    month: [],
    user_email: [],
    created_at: [],
    earning_cents: [],
    tax_thousands: [],
    tax_withheld_cents: [],
    paid_cents: [],
    manual: undefined,
    source: [],
    source_id: [],
    content_type: [],
    source_category: [
      SourceCategory.Pro,
      SourceCategory.Free,
      SourceCategory.Editorial,
      SourceCategory.None,
    ],
    region: [],
    invoice_code: [],
    sort_field: 'month',
    sort_direction: SortOrder.Desc,
    user_state: [
      AccountStatus.Approved,
      AccountStatus.Rejected,
      AccountStatus.Unapproved,
      AccountStatus.Closed,
    ],
    paid_at: [],
  }

  @observable filters: GetApiV1FinanceChargesQueryParams =
    initFilters<GetApiV1FinanceChargesQueryParams>(this.defaultFilters)
  @observable bulkOperations: FinanceBulkOperation[] = []

  constructor() {
    makeObservable(this)
  }

  @action setFilters = (
    filters: GetApiV1FinanceChargesQueryParams,
    syncUrl = false
  ): void => {
    this.filters = filters

    if (syncUrl) {
      const url =
        Routes.EarningsCharges +
        qs.stringify(removeEmpty(this.filters), {
          encode: false,
          arrayFormat: 'comma',
          addQueryPrefix: true,
        })
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.setFilters(this.defaultFilters, true)
  }

  @action addBulkOperation = (operation: FinanceBulkOperation) => {
    this.bulkOperations = [...this.bulkOperations, operation]
  }

  @action removeBulkOperation = (operation: FinanceBulkOperation) => {
    this.bulkOperations = [...this.bulkOperations].filter(
      op => op.id !== operation.id
    )
  }

  @action updateBulkOperation = (operation: FinanceBulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex(
        (op: FinanceBulkOperation) => op.id === operation.id
      ),
      1,
      operation
    )

    this.bulkOperations = operations
  }
}
