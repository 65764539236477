import { captureError } from 'helpers/error'
import {
  action,
  autorun,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx'
import AuthStore from 'stores/AuthStore'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'
import { ReviewCounts, ReviewSessionMetrics } from '../api'

export default class GeneralReviewStore {
  api: Api
  AuthStore: AuthStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        this.restoreMetricsFromLocalStorage()

        autorun(() => {
          localStorage.setItem(
            'filesReviewedCount',
            this.filesReviewedCount.toString()
          )
          localStorage.setItem(
            'reviewSessionCount',
            this.reviewSessionCount.toString()
          )
          localStorage.setItem('filesReviewedDate', this.filesReviewedDate)
        })
      }
    )
  }

  @observable filesReviewedMetrics: ReviewCounts | null = null
  @observable reviewSessionMetrics: ReviewSessionMetrics | null = null
  @observable currentReviewedContributor = ''
  @observable currentReviewSessionId = ''
  @observable filesReviewedCount = 0
  @observable reviewSessionCount = 0
  @observable loadingMetrics = false
  @observable loadingReviewSessionMetrics = false
  @observable reviewedContributorLoading = false
  @observable isReviewSessionModalOpen = false
  @observable hasReviewSessionEnded = false
  @observable startingReviewSession = false
  @observable loadingReviewSession = false
  @observable filesReviewedDate = ''

  @action setFilesReviewedCount = (count: number) => {
    const currentCount = this.filesReviewedCount
    const newCount = currentCount + count
    const today = this.getTodayString()

    const currentReviewSessionCount = this.reviewSessionCount
    const newReviewSessionCount = currentReviewSessionCount + count

    this.filesReviewedCount = newCount
    this.reviewSessionCount = newReviewSessionCount

    if (this.filesReviewedDate !== today) {
      this.filesReviewedCount = 0
      this.filesReviewedDate = today
    }
  }

  @action fetchFileReviewCount = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (!session) return

    try {
      const savedFilesReviewed = localStorage.getItem('filesReviewedCount')
      const localCount = savedFilesReviewed ? Number(savedFilesReviewed) : 0

      if (localCount > 0) {
        const { count: apiCount } = await this.api.request(
          'GET',
          `/api/v1/admin/review_metrics/${session.id}/today_count`
        )

        if (apiCount === localCount || apiCount > localCount) {
          runInAction(() => {
            this.filesReviewedCount = apiCount
          })
        } else {
          runInAction(() => {
            this.filesReviewedCount = localCount
          })
        }
      } else {
        const { count: apiCount } = await this.api.request(
          'GET',
          `/api/v1/admin/review_metrics/${session.id}/today_count`
        )
        runInAction(() => {
          this.filesReviewedCount = apiCount
        })
      }
    } catch (error) {
      captureError(
        error,
        'Error while fetching file review count - stores/GeneralReviewStore.ts'
      )
    }
  }

  @action fetchFileReviewMetrics = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (session) {
      try {
        this.loadingMetrics = true
        const metrics = await this.api.request(
          'GET',
          `/api/v1/admin/review_metrics/${session.id}/daily_hourly_counts`
        )
        runInAction(() => {
          this.filesReviewedMetrics = metrics
        })
      } catch (error) {
        captureError(
          error,
          'Error while fetching file review metrics - stores/GeneralReviewStore.ts'
        )
      } finally {
        runInAction(() => {
          this.loadingMetrics = false
        })
      }
    }
  }

  @action startNewReviewSession = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (session) {
      try {
        this.startingReviewSession = true
        const reviewSession = await this.api.request(
          'POST',
          `/api/v1/admin/review_sessions`
        )

        runInAction(() => {
          if (reviewSession && reviewSession.id) {
            this.currentReviewSessionId = reviewSession.id
          }
        })
      } catch (error) {
        captureError(
          error,
          'Error while creating new review session - stores/GeneralReviewStore.ts'
        )
      } finally {
        runInAction(() => {
          this.startingReviewSession = false
        })
      }
    }
  }

  @action endReviewSession = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (session) {
      try {
        this.loadingReviewSessionMetrics = true
        await this.api.request(
          'PATCH',
          `/api/v1/admin/review_sessions/end_session`
        )
        const metrics = await this.api.request(
          'GET',
          `/api/v1/admin/review_sessions/metrics`
        )

        runInAction(() => {
          this.reviewSessionMetrics = metrics
        })

        if (
          (metrics && metrics.resource_count && metrics.resource_count > 0) ||
          this.reviewSessionCount > 0
        ) {
          runInAction(() => {
            this.isReviewSessionModalOpen = true
          })
        }
      } catch (error) {
        captureError(
          error,
          'Error while fetching review session metrics - stores/GeneralReviewStore.ts'
        )
      } finally {
        runInAction(() => {
          this.loadingReviewSessionMetrics = false
        })
      }
    }
  }

  @action getLatestReviewSession = async (): Promise<void> => {
    const session = this.AuthStore.session
    if (session) {
      try {
        this.loadingReviewSession = true
        const reviewSession = await this.api.request(
          'GET',
          `/api/v1/admin/review_sessions/latest`
        )

        runInAction(() => {
          if (reviewSession && reviewSession.id) {
            this.currentReviewSessionId = reviewSession.id
          }
        })
      } catch (error) {
        captureError(
          error,
          'Error while fetching latest review session - stores/GeneralReviewStore.ts'
        )
      } finally {
        runInAction(() => {
          this.loadingReviewSession = false
        })
      }
    }
  }

  @action setCurrentReviewedContributor = (contributoId: string) => {
    this.currentReviewedContributor = contributoId
  }

  @action setReviewedContributorLoading = (loading: boolean) => {
    this.reviewedContributorLoading = loading
  }

  @action setCurrentReviewSessionId = (id: string) => {
    this.currentReviewSessionId = id
  }

  @action setHasReviewSessionEnded = (value: boolean) => {
    this.hasReviewSessionEnded = value
  }

  @action setIsReviewSessionModalOpen = (value: boolean) => {
    if (value === false) {
      this.reviewSessionCount = 0
    }

    this.isReviewSessionModalOpen = value
  }

  @action restoreMetricsFromLocalStorage = () => {
    const savedFilesReviewed = localStorage.getItem('filesReviewedCount')
    const savedReviewSession = localStorage.getItem('reviewSessionCount')
    const savedDate = localStorage.getItem('filesReviewedDate')
    const today = this.getTodayString()

    runInAction(() => {
      if (savedDate === today) {
        this.filesReviewedCount = savedFilesReviewed
          ? Number(savedFilesReviewed)
          : 0
        this.reviewSessionCount = savedReviewSession
          ? Number(savedReviewSession)
          : 0
        this.filesReviewedDate = today
      } else {
        this.filesReviewedCount = 0
        this.reviewSessionCount = savedReviewSession
          ? Number(savedReviewSession)
          : 0
        this.filesReviewedDate = today
      }
    })
  }

  getTodayString(): string {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }
}
