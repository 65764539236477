import { File as ApiFile } from 'types/api'

export type FileImportFailure = {
  file: ApiFile
  error_keys: string[]
}

export type UploadProgress = {
  totalBytes?: number
  totalPercent: number
}

export class FileUpload {
  public batchId?: string
  constructor(public file: File) {
    this.name = file.name
    this.type = file.type
  }
  public name?: string
  public path?: string
  public type?: string
  public url?: string
  public mimetype?: string
  public status?: 'loading' | 'success' | 'error'
  public startTime?: number
  public progress?: UploadProgress
  public errors?: string[]
}

export type FilestackFile = {
  container: string
  key: string
  mimetype: string
  filename?: string
  handle?: string
  name?: string
  size?: number
  status?: string
  type?: string
  url: string
}

export type FTPJob = {
  remote_file_key?: string
  error_keys?: string[]
  state?: string
}
