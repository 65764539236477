import { ResourceFileType } from 'types'
import { Category } from 'types/api'

type UrlValue = null | string
type FilterValue = null | string | string[]

const parseArrays = (value: UrlValue, fallback: FilterValue): FilterValue => {
  const arr = value ? value.split(',').filter(Boolean) : fallback
  return Array.isArray(fallback) ? arr : value || fallback
}

export function initFilters<T extends object>(filters: T): T {
  const query = new URLSearchParams(window.location.search)

  return Object.keys(filters).reduce((acc, key) => {
    // @ts-ignore ($FixMe)
    acc[key] = parseArrays(query.get(key), filters[key])
    return acc
  }, {} as T)
}

export const getFileTypesFromCategories = (
  categories: Category[]
): ResourceFileType[] => {
  const result: ResourceFileType[] = []

  categories.forEach(cat => {
    for (const type of cat.file_types) {
      if (!result.includes(type)) {
        result.push(type)
      }
    }
  })

  return result
}

export const notAll = (value: string[] | undefined | null) => {
  if (value && value.includes('all')) {
    return undefined
  }

  return value
}
