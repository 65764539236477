import { captureError, getErrorMsg } from 'helpers/error'
import cookie from 'js-cookie'
import { useSnackbar } from 'notistack'
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetApiV1Users, usePostApiV1SessionSwitchUser } from 'api'
import { User } from 'types/api'

import * as S from './styled'

type Props = {
  onSelect(user: User): void
  onClear?: () => void
  className?: string
  placeholder?: string
  defaultValue?: User
  testId?: string
  disabled?: boolean
  withLoginAs?: boolean
}

// mostly ripped off from https://material-ui.com/components/autocomplete/
function UserSearch({
  className,
  onSelect,
  onClear,
  testId,
  disabled,
  placeholder,
  defaultValue,
  withLoginAs,
}: Props): JSX.Element {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState<User | null>(defaultValue || null)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const { data: users, loading: netLoading } = useGetApiV1Users({
    queryParams: { query },
    debounce: 500,
  })
  const { mutate: switchUser, loading: switchLoading } =
    usePostApiV1SessionSwitchUser({})

  // This is required to show the loading spinner while the debounce is happening
  useEffect(() => {
    setLoading(netLoading)
  }, [netLoading])

  useEffect(() => {
    setLoading(true)
  }, [query])

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const onChange = (event: SyntheticEvent, user: User) => {
    onSelect(user)
    setValue(user)
  }

  const onInputChange = (event: SyntheticEvent, value: string) => {
    setQuery(value)
  }

  const onSelectOption = (option: User) => {
    setOpen(false)
    onSelect(option)
    setValue(option)
  }

  const onReset = () => {
    setQuery('')
    setValue(null)
    onClear && onClear()
  }

  const onLoginAs = (ev: MouseEvent<HTMLElement>, user: User) => {
    ev.preventDefault()
    ev.stopPropagation()
    switchUser({ user_id: user.id })
      .then(newSession => {
        cookie.set(`eezy-cm-auth-token`, newSession.api_token)
        window.location.replace('/')
      })
      .catch(error => {
        captureError(
          error,
          `Error occurred during switchUser in UserSearch. Id of user being logged in as: ${user.id}`
        )
        enqueueSnackbar(getErrorMsg(error), { variant: 'error' })
      })
  }

  const searchAdornment = disabled ? null : loading || switchLoading ? (
    <S.UserSearchLoading color="inherit" size={14} />
  ) : query.length ? (
    <S.ClearIcon onClick={onReset} />
  ) : (
    <S.SearchIcon />
  )

  const renderOption = (props: Props, option: User) => {
    if (withLoginAs) {
      return (
        <S.SearchOption
          key={`ac-option-${option.id}`}
          onClick={() => onSelectOption(option)}
        >
          {option.email}

          <S.LoginAsButton
            size="tiny"
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(ev: any) => onLoginAs(ev, option)}
          >
            Login As
          </S.LoginAsButton>
        </S.SearchOption>
      )
    }

    return (
      <S.SearchOption
        key={`ac-option-${option.id}`}
        onClick={() => onSelectOption(option)}
      >
        {option.email}
      </S.SearchOption>
    )
  }

  return (
    <S.StyledUserSearch
      fullWidth
      disabled={Boolean(disabled)}
      className={className}
      size="small"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={value}
      onChange={onChange}
      inputValue={query}
      onInputChange={onInputChange}
      isOptionEqualToValue={(option: User, value: User) =>
        option.id === value.id
      }
      renderOption={renderOption}
      getOptionLabel={(option: User) => option.email}
      options={users || []}
      loading={loading}
      loadingText={t('util.loading')}
      PaperComponent={S.Wrapper}
      noOptionsText={t('util.no_results')}
      renderInput={(params: $FixMe) => (
        <S.SearchField
          {...params}
          data-testid={testId}
          placeholder={placeholder || t('nav.admin.user_search')} // i18n-use-tasks t('nav.admin.user_search')
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: searchAdornment,
          }}
        />
      )}
    />
  )
}

export default UserSearch
